import axios from 'axios';
import API_URL from '../backend'
import authHeader from './auth-header.service';


class EmailConfigService {
    getInfo(){
        const axiosRequest = {
            method: 'get',
            url: API_URL+'/admin/configEmail/info',
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
    actualizarDatos(datosMail){
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/admin/configEmail/actualizar',
            data:datosMail,
            headers: authHeader()
        }
        return axios(axiosRequest);
    }
}
export default new EmailConfigService();