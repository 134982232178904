<template lang="html">
  <section class="v-buzon-config mb-auto">
    <h3>Configuración del mail</h3>
    <form
      @submit.prevent="actualizarDatosMail"
      class="
        p-fluid
        d-flex
        flex-wrap
        ml-auto
        mr-auto
        p-1 p-lg-2
        col-12 col-lg-8
        justify-content-center
        align-content-between
      "
    >
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="far fa-envelope ml-1 mr-1"></i>
        <InputText
          id="mail"
          name="Email"
          type="email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          v-model="mail.xemail"
          placeholder="Email"
          required
        />
        <label for="email" class="d-none">Email</label>
      </span>
      <span class="p-input-icon-left mt-4 p-col-12">
        <i class="far fa-envelope ml-1 mr-1"></i>
        <Password
          id="contrasena"
          name="contrasena"
          type="email"
          v-model="mail.xcontrasena"
          toggleMask
          :feedback="false"
          placeholder="Contraseña"
          required
        />
        <label for="contrasena" class="d-none">Contraseña</label>
      </span>
      <div class="col-8">
        <vs-button border type="submit" block>
          <i class="pi pi-replay mr-2"></i
          ><span class="text-lg">Actualizar datos</span>
        </vs-button>
      </div>
    </form>
  </section>
</template>

<script lang="js">
  import EmailConfigService from '../../services/emailConfig.service.js'
  export default  {
    name: 'v-buzon-config',
    props: [],
    mounted () {
      EmailConfigService.getInfo().then((response)=>{
        this.mail = response.data[0];
      }).catch((error)=>{
        this.$toast.add({
          severity:'error',
          summary:'Error',
          detail:error
        })
      })
    },
    data () {
      return {
        mail:{}
      }
    },
    methods: {
      actualizarDatosMail(e){
        e.preventDefault();
        EmailConfigService.actualizarDatos(this.mail).then((response)=>{
          this.$toast.add({
            severity: response.data.success ? 'info' : 'error',
            summary : response.data.success ? 'Actualizado' : 'Error',
            detail: response.data.description,
            life:response.data.success ? 5000 : null
          });
          this.mail.xcontrasena = '';
        }).catch((error)=>{
          this.$toast.add({
          severity:'error',
          summary:'Error',
          detail:error
        })
        })
      }
    },
    computed: {

    }
}
</script>

<style scoped lang="scss">
.v-buzon-config {
}
</style>
